.socialBar {
    .sn-social-media-list {
        background: #00BECC!important;
        text-align: center;
        padding: 8px 15px!important;
      .sn-social-media-icon {
        background: #fff!important;
        border-radius: 5px;
        font-size: 1.75em;
        margin: 7px;
        color: #00BECC;
      }
    }
  }