//Opta Scroller Widget
.Opta_F_F.Opta_F_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Team {
   background-color: #fff !important;
   border-right: 1px solid $gray !important;
}

.Opta_F_F.Opta_F_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-timings {
   background-color: #fff !important;
   border-right: 1px solid $gray !important;
}


@media (max-width: 1024px){

  .desktop-widget {
    display: none !important;
  }

}

//Opta Scoreboard Widget
$scoreboard-height: 110px;

#home .siteContainer .styled-scoreboard {

  .pageElement { padding: 0; }

  .Opta > .Opta_W { margin: 0; }

  .Opta_F_F.Opta_F_F_S {

    .Opta-Scroll {
      height: $scoreboard-height;

      .Opta-Window {
        height: $scoreboard-height;

        .Opta-Room {
          height: $scoreboard-height;

          .Opta-fixture { padding: 10px 0; }

        }

      }

      .Opta-Scroller {
        padding: 55px 0;
        background: $gray;

        &.Opta-Active {
          background: $dark;
          transition: background $transition-standard;

          &:hover { background: $secondary-color; }

        }

      }

    }

  }

}

// Opta Headers/Table Headers
@mixin header-text-style{
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .5px;
}

// Opta General Styling
.Opta {

  a { 
    color: $league-one;
    transition: color $transition-linear;
    &:hover {
      color: #000;
    }
  }

  h2 {
    background: $league-one;
    margin-bottom: 1px;

    .Opta-Title {
      @include header-text-style;
      font-family: $fontOne;
    }

  }

  h4, h3 {

    > span {
      @include header-text-style;
      text-transform: none;
    }

  }

  .Opta-Nav li.Opta-On a,
  .Opta-TimeControls .Opta-PeriodButtons button.Opta-On {
    background: $league-one;

    &:hover { 
      background: $pop-color;
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }
    }

  }

  .Opta-Stat .Opta-Value { color: $league-one; }

  .Opta-Ranking,
  .Opta-Ranking-Bars {

    .Opta-FeatureHeader {
      td, th { 
        background-color: $championship;
        .opta-championship & {
          background-color: $championship;
        }
        .opta-league-one & {
          background-color: $league-one;
        }
        .opta-league-two & {
          background-color: $league-two;
        }
      }

      td {
        .Opta-Team,
        &.Opta-Games div,
        &.Opta-Score div { color: #fff; }
      }

      a { color: #fff; }
    }

    .Opta-Score .Opta-Rating { text-shadow: none; }

  }

  .Opta_F_TR_N .Opta-Striped tbody tr.Opta-FeatureHeader {
    td, th {
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }

      .Opta-Team,
      .Opta-Team a { color: #fff; }

    }
  }

  .Opta_F_CS_N .Opta-stats-active .Opta-Label:after {
    color: $pop-color;
    .opta-championship & {
      color: $championship;
    }
    .opta-league-one & {
      color: $league-one;
    }
    .opta-league-two & {
      color: $league-two;
    }
    font-family: FontAwesome;
    content: '\f101';
  }



  .Opta-TimeControls {
    .Opta-Dragger path { fill: $league-one; }
    .Opta-TimeSpan {
      fill: $pop-color;
      .opta-championship & {
        fill: $championship;
      }
      .opta-league-one & {
        fill: $league-one;
      }
      .opta-league-two & {
        fill: $league-two;
      }
    }
  }

  p.Opta-Load { 
    background: url('../images/loader.gif') center 50px no-repeat #FFF;
    filter: grayscale(100);
    // .opta-championship & {
    //   background: url('../images/loader-championship.gif') center 50px no-repeat #FFF;
    // }
    // .opta-league-one & {
    //   background: url('../images/loader-league-one.gif') center 50px no-repeat #FFF;
    // }
    // .opta-league-two & {
    //   background: url('../images/loader-league-two.gif') center 50px no-repeat #FFF;
    // }
  }

  .Opta_F_CB .Opta-events-button { background: $coal; }

  .Opta_F_F.Opta_F_F_G .Opta-fixture {

    .Opta-Venue,
    .Opta-Team span {
      font-family: $fontOne;
      letter-spacing: .5px;
    }


    .Opta-Venue { font-size: 22px; }
    .Opta-Team span { font-size: 18px; }

  }

  .Opta-Stats-Bars .Opta-Bars-Full .Opta-Percent {
    background: $championship;

    &.Opta-Away { 
      background: $pop-color;
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }
    }

  }

  .Opta-FootballPitch .Opta-Node circle {

    &.Opta-Home { fill: $championship; }
    &.Opta-Away { 
      fill: $pop-color; 
      .opta-championship & {
        fill: $championship;
      }
      .opta-league-one & {
        fill: $league-one;
      }
      .opta-league-two & {
        fill: $league-two;
      }
    }

  }

  div.Opta-Dropdown.Opta-Active label.Opta-Dropdown-Label {

    span.Opta-Dropdown-Label-Button,
    span.Opta-Dropdown-Label-Text { background-color: $championship; }

    &:hover {

      span.Opta-Dropdown-Label-Button,
      span.Opta-Dropdown-Label-Text {
        background-color: $pop-color;
        .opta-championship & {
          background-color: $championship;
        }
        .opta-league-one & {
          background-color: $league-one;
        }
        .opta-league-two & {
          background-color: $league-two;
        }
      }

    }

  }

  .Opta_F_TL .Opta-Timeline > div { background-color: $championship; }

  .Opta-MatchHeader .Opta-Score {

    .Opta-Team-Score { 
      background-color: $championship;
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }
    }

    &.Opta-Away .Opta-Team-Score { background-color: $black; }

  }

  .Opta-Teamsheet-Holder {

    &.Opta-Home {
      .Opta-Teamsheet-Button { background: $championship; }
    }
    &.Opta-Away {
      .Opta-Teamsheet-Button {
        background: $pop-color;
        .opta-championship & {
          background-color: $championship;
        }
        .opta-league-one & {
          background-color: $league-one;
        }
        .opta-league-two & {
          background-color: $league-two;
        }
      }
    }
  }

  .Opta-Ranking-Bars .Opta-Score div { 
    background-color: darken($pop-color, 5%); 
    .opta-championship & {
      background-color: darken($championship, 5%);
    }
    .opta-league-one & {
      background-color: darken($league-one, 5%);
    }
    .opta-league-two & {
      background-color: darken($league-two, 5%);
    }
  }

  .Opta-Stats-Radial .Opta-Stat path.Opta-Arc,
  .Opta-Stat .Opta-Value,
  .Opta-Stats-Cross .Opta-Stat circle { fill: $championship; }

  // Player Goal icon-size
  .Opta-Stats-Goal-Methods {

    .Opta-Goal-Method-Left .Opta-Goal-Figure { background-image: url('https://assets.ngin.com/site_files/usl/_site/images/opta/goal-left-foot.svg'); }
    .Opta-Goal-Method-Right .Opta-Goal-Figure { background-image: url('https://assets.ngin.com/site_files/usl/_site/images/opta/goal-right-foot.svg'); }
    .Opta-Goal-Method-Headed .Opta-Goal-Figure { background-image: url('https://assets.ngin.com/site_files/usl/_site/images/opta/goal-headed.svg'); }
    .Opta-Goal-Method-Other .Opta-Goal-Figure { background-image: url('https://assets.ngin.com/site_files/usl/_site/images/opta/goal-other.svg'); }

  }

  .Opta_W.Opta_F_ML_N .Opta-Overlay li.Opta-Circle{
    &.Opta-Home { background-color: $championship; }
    &.Opta-Away { 
      background-color: $pop-color;
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }
    }
  }

  .Opta-Record > span .tooltip h3 { background-color: $championship; }

  .Opta_F_H_N .Opta-FootballPitch .Opta-touch {
    &.Opta-home {
      fill: $championship;
      stroke: none;
    }
    &.Opta-away {
      fill: $pop-color;
      .opta-championship & {
        fill: $championship;
      }
      .opta-league-one & {
        fill: $league-one;
      }
      .opta-league-two & {
        fill: $league-two;
      }
      stroke: #fff;
    }
  }

  .Opta_W .Opta-Tooltip {
     h3 { background: $championship; }
  }

  .Opta-Stats-Bars.Opta-Stats-Bars-Simple .Opta-Bar-Track .Opta-Bar-Fill { background: $championship; }

  .Opta_F_CB .Opta-FootballPitch .Opta-Player{
    &.Opta-Away .Opta-Shape {
      fill: $pop-color;
      .opta-championship & {
        fill: $championship;
      }
      .opta-league-one & {
        fill: $league-one;
      }
      .opta-league-two & {
        fill: $league-two;
      }
    }
    &.Opta-Home .Opta-Shape { fill: $championship; }
  }

  .Opta-Ranking.Opta-Away .Opta-Score {
    div { 
      background-color: $championship;
      .opta-championship & {
        background-color: $championship;
      }
      .opta-league-one & {
        background-color: $league-one;
      }
      .opta-league-two & {
        background-color: $league-two;
      }
    }
    .Opta-Rating { text-shadow: none; }
  }

  .Opta-Ranking.Opta-Home .Opta-Score {
    div { background: $championship; }
    .Opta-Rating { text-shadow: none; }
  }

  .Opta_F_F.Opta_F_F_G .Opta-fixture .Opta-Team span a {
    font-size: 18px;
    font-weight: 100;
  }

}

.Opta_F_F.Opta_F_F_G .Opta-fixture h3 abbr,
.Opta_F_F.Opta_F_F_G .Opta-fixture h3 span { font-size: 20px; }


//Match
.Opta_F_F.Opta_F_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-match-linking {
 background-color: $championship;
}
