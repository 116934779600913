.theme-nav-style-slide .theme-slide-nav-toggle {
  margin-top: 45px;
}
html.collapsed-mobile-nav:not(.slide-nav-open) .theme-slide-nav-toggle {
  margin-top: -50px;
}
.theme-nav-style-slide .theme-nav-title {
  margin-top: 45px;
}
.sj-search-bar-input-common {
  max-width: 100%;
}
 .sj-suggestion {
   font-family: $fontTwo;
 }
 .sj-suggestions {
   z-index: 1 !important;
   .nav-fixed & {
     display: none;
     transition: .2s linear;
   }
 }
 #nav-search-box .sj-suggestions {
   margin-top: 0px !important;
 }
.theme-search-bar .se-sajari-container {
  margin-right: 0px;
  &:after {
    content: "";
    border-right: 1px solid #ebebeb;
    height: 100%;
    display: block;
    position: absolute;
    margin-right: 41px;
    width: 46px;
  }
}
#nav-search-box-controller{
  .nav-fixed & {
    display: none;
    transition: .2s linear;
  }
  .network-open & {
    display: block;
  }
}

@media (max-width: 768px) {
    .sj-result-image-container img {
      max-height: 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: 100%;
  }
}

#se-sajari-mobile-container {
  border-top: 1px solid rgba(153, 153, 153, 0.2);
}
